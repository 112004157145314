import { useState } from 'react';

const Login = ({ user, setUser, setCurrentPage }) => {
  let [loginName, setLoginName] = useState('');
  let [password, setPassword] = useState('');
  let [errorText, setErrorText] = useState('');

  function handleLogin() {
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'username': loginName, //this is correct! Don't change
        'password': password
      })
    }).then(response => {
      //console.log(response)
      // If we get a 401 response, the user is not logged in
      if (response.status === 401 || response.status === 400) {
        //alert('Incorrect username or password');
        setErrorText("No user found with that username and password.");
        console.log(response)

        return;
      }
      if (response.status === 502 || response.status === 500) {
        alert('Server unavailable. Please try again in a couple of minutes.');
        setErrorText('Server unavailable. Please try again in a couple of minutes.');
        return;
      }

      // If we get a 200 response, convert the response to JSON
      if (response.status === 200) {
        let local = response;

        local.json()
          .then(response => {
            let final = response;
            localStorage.setItem('user', JSON.stringify(final));
            if (final.type === "recipient")
              setCurrentPage('viewBikesPage')
            setUser(final)


          })

      }
    })
      .catch(error => alert(error))

  }

  function updateLoginName(event) {
    const target = event.target;
    const value = target.value;
    setLoginName(value);
  }

  function updatePassword(event) {
    const target = event.target;
    const value = target.value;
    setPassword(value);
  }

  function recoverPassword(){
    //console.log(userName);
    let loginName = prompt('Type in your Username (login name):');
    if (loginName){
      //console.log(loginName)
      fetch('/recoverPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'login_name': loginName
        })
      }).then(response => {
        //console.log(response)
        // If we get a 401 response, the user is not logged in
        if (response.status === 401 || response.status === 400) {
          //alert('Incorrect username or password');
          setErrorText('Incorrect username or password');
          return;
        }
        if (response.status === 502 || response.status === 500) {
          alert('Server unavailable. Please try again in a couple of minutes.');
          setErrorText('Server unavailable. Please try again in a couple of minutes.');
          return;
        }
  
        // If we get a 200 response, convert the response to JSON
        if (response.status === 200) {
          let local = response;
          //console.log(local)
  
          local.text()
            .then(response => {
              let final = response;
              //console.log(final)
              alert(final);
  
  
            })
  
        }
      })
        .catch(error => alert(error))
  
    }else{
      // alert("Please fill your email.")
    }
  }

  function recoverEmailPassword(){
    //console.log(userName);
    let email = prompt('Type in your email:');
    if (email){
      //console.log(email)
      fetch('/recoverPasswordByEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'email': email
        })
      }).then(response => {
        //console.log(response)
        // If we get a 401 response, the user is not logged in
        if (response.status === 401 || response.status === 400) {
          //alert('Incorrect username or password');
          setErrorText('Incorrect email');
          return;
        }
        if (response.status === 502 || response.status === 500) {
          alert('Server unavailable. Please try again in a couple of minutes.');
          setErrorText('Server unavailable. Please try again in a couple of minutes.');
          return;
        }
  
        // If we get a 200 response, convert the response to JSON
        if (response.status === 200) {
          let local = response;
          //console.log(local)
  
          local.text()
            .then(response => {
              let final = response;
              //console.log(final)
              alert(final);
  
  
            })
  
        }
      })
        .catch(error => alert(error))
  
    }else{
      // alert("Please fill your email.")
    }
  }

  return (

    <section id="content" className="wrapper">
      <h3>Login</h3>
      
      <p className="note"><small><b>NOTE:</b> The system <b>does not</b> log you out automatically. We strongly recommend you Logout at the end of each session.</small></p>
      <br/>
      <form action="/login" method="post">
        <div>
          <label>Username:</label>
          <input onChange={updateLoginName} type="text" name="login_name" id="login_name" required /><br />
          <label>Password:</label>
          <input onChange={updatePassword} type="password" name="password" id="password" required />
          <p className="note"><small><b>FORGOT PASSWORD?</b> Click <span className="recoverPasswordButton"><button type="button" onClick={recoverPassword}>here</button> to type in your username and receive a password recovery email.</span></small></p>
          <p className="note"><small><b>FORGOT USERNAME and/or PASSWORD?</b> Click <span className="recoverPasswordButton"><button type="button" onClick={recoverEmailPassword}>here</button> to type in your email and receive a username and password recovery email.</span></small></p>
          
        </div>
         <div className="centering">
         <button type="button" onClick={handleLogin} value="Submit" className="otherButton">Login</button>
        </div>
        <p className="erroNoLogin">
          {errorText ? errorText : null}</p>
      </form>
    </section>

  )
}
export default Login;