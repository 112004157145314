import { useState, useEffect } from 'react';
import Select from 'react-select'

const EditUser = ({ user, bikes, superUser, logout }) => {

    let [chosenUser, setChosenUser] = useState([]);
    let [users, setUsers] = useState();
    useEffect(() => {
        loadUsers();
    }, []);
    function loadUsers() {
        let data = {};
        fetch("/getAllUsers", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                // console.log(final);
                let finalFiltered = final.filter(u => u.status !== "Deleted" && u.status !== "deleted")
                //console.log(finalFiltered)

                //console.log('Original',finalFiltered)
                finalFiltered.sort((a, b) => (a.email > b.email) ? 1 : -1)
                //console.log('Ordered:',finalFiltered)
                setUsers(finalFiltered);
                setChosenUser([]);
            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the users. Please logout, login and try again. Error: " + e)
                    logout()
                }

            )


    }

    let [pickupSlots, setPickupSlots] = useState([]);
    useEffect(() => {
        loadPickupSlots();
    }, []);
    useEffect(() => {
        loadPickupSlots();
    }, [chosenUser]);
    function loadPickupSlots() {
        let data = {};
        fetch("/getAllDates", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                // console.log(final);
                setPickupSlots(final);

            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the pickup slots. Please logout, login and try again. Error: " + e)
                    logout()
                }

            )


    }

    let [locations, setLocations] = useState([]);
    useEffect(() => {
        loadLocations();
    }, []);
    function loadLocations() {

        fetch("/getAllLocations")
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                // console.log(final);
                setLocations(final);

            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the locations. Please logout, login and try again. Error: " + e)
                    logout()
                }

            )


    }
    useEffect(() => {
        loadLocationOptions();
    }, [chosenUser]);
    function loadLocationOptions() {

        if (chosenUser && document.getElementById("location")) {
            let locs = document.getElementById("location");
            locs.innerHTML = '';
            let option = document.createElement("option");
            option.text = "-- Choose --";
            option.value = null;
            locs.add(option);
            locs.selectedIndex = "0";
            if (locations.length > 0) {
                locations.forEach(l => {
                    let option = document.createElement("option");
                    option.text = l.id + " - " + l.name;
                    option.value = l.id;
                    locs.add(option);
                })
            }
            locs.value = chosenUser.location + "";
        } else {

        }
    }

    function editUser() {
        //console.log('Edit user!', chosenUser);
        let localUser = { ...chosenUser };
        let today = new Date();
        var newLine = "\r\n";
        if (localUser.log)
            localUser.log += "[" + today + "] User updated by " + user.first_name + "." + newLine;
        else
            localUser.log = "[" + today + "] User updated by " + user.first_name + "." + newLine;

        localUser.status = "edited";

        fetch('/updateUser', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(localUser)
        }).then(response => {
            if (response.status === 200) {
                alert('User Updated.');
                loadUsers();
            } else {
                // read the response body as plain text
                response.text().then(text => {
                    // console.log('Error updating user: ' + text);
                    alert('Error updating user: ' + text)
                });
            }
        }).catch(error => {
            //console.log(error);
            alert('Error updating user: ' + error)
        });
    }

    function deleteUser() {
        if (window.confirm("Are you SURE you want to delete " + chosenUser.login_name + "? This action CANNOT BE REVERSED.")) {
            if (chosenUser.bike_id) {
                alert("You cannot delete this user. This user has a bike reserved. If you want to delete this user, cancel the reservation in Schedule first.")
                return
            }
            let localUser = { ...chosenUser };
            let today = new Date();
            var newLine = "\r\n";
            if (localUser.log)
                localUser.log += "[" + today + "] User " + chosenUser.login_name + " deleted by " + user.first_name + "." + newLine;
            else
                localUser.log = "[" + today + "] User " + chosenUser.login_name + " deleted by " + user.first_name + "." + newLine;

            //console.log(localUser);
            fetch('/deleteUser', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(localUser)
            }).then(response => {
                if (response.status === 200) {
                    alert('User Updated.');

                    loadUsers();
                } else {
                    // read the response body as plain text
                    response.text().then(text => {
                        // console.log('Error updating user: ' + text);
                        alert('Error updating user: ' + text)
                    });
                }
            }).catch(error => {
                //console.log(error);
                alert('Error updating user: ' + error)
            });
        }
    }


    function createBackup() {

        fetch("/createBackup", {
            method: "GET",
        }).then(response => {
            response.text().then(text => {
                alert(text);

            })
        });
    }

    function handleChangeInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(event.target);
        //console.log(value);


        let localData = { ...chosenUser };
        if (name === "pickup_slot" || name === "priority") {
            localData[name] = parseInt(value);
        }
        else
            localData[name] = value;

        //console.log(localData);
        setChosenUser(localData);
    }


    function loadUsersSelect() {

        let selectUser = document.getElementById('emailBox');

        selectUser.innerHTML = '';
        let opt = document.createElement('option');
        opt.value = 'choose';
        opt.innerHTML = "-- Choose --";
        selectUser.appendChild(opt);

        for (let i = 0; i < users.length; i++) {
            if (user.type === "superAdmin" || (users[i].location === user.location || (users[i].type !== "admin" && users[i].type !== "superAdmin"))) {
                let opt = document.createElement('option');
                let field = users[i].bike_size ? users[i].bike_size : users[i].type.toUpperCase();
                let bike = bikes.filter(b => b.id === users[i].bike_id)[0];
                let bStatus = users[i].bike_id ? bike.status === "Donated" ? "Delivered" : bike.status : null;
                let bikeNumber = users[i].bike_id ? " - Bike#" + users[i].bike_id + " (" + bStatus + ") " : "";
                opt.value = users[i].login_name;
                opt.innerHTML = users[i].first_name + " " + users[i].last_name + " (" + users[i].login_name + " - " + users[i].id + ") - " + users[i].email + " - " + field + bikeNumber;
                selectUser.appendChild(opt);
            }
        }
    }

    function loadUsersSelectNew() {

        let finalOptions = [];
        if (!users) {
            return finalOptions;
        }

        for (let i = 0; i < users.length; i++) {
            if (user.type === "superAdmin" || (users[i].location === user.location || (users[i].type !== "admin" && users[i].type !== "superAdmin"))) {
                let field = users[i].bike_size ? users[i].bike_size : users[i].type.toUpperCase();
                let bike = bikes.filter(b => b.id === users[i].bike_id)[0];
                let bStatus = users[i].bike_id ? bike.status === "Donated" ? "Delivered" : bike.status : null;
                let bikeNumber = users[i].bike_id ? " - Bike#" + users[i].bike_id + " (" + bStatus + ") " : "";
                let v = users[i].login_name;
                let l = users[i].first_name + " " + users[i].last_name + " (" + users[i].login_name + " - " + users[i].id + ") - " + users[i].email + " - " + field + bikeNumber;
                finalOptions[i] ={value: v, label: l}
                //console.log(finalOptions[i])
                
            }
            
        }
        return finalOptions;
    }


    let [availablePickUpSlots, setAvailablePickUpSlots] = useState();
    function loadThisUser(event) {
        //console.log("LOAD THIS USER: "+event.target.value)
        let chosen = users.filter(u => u.login_name === event.target.value)[0];
        //console.log("ChosenUser: ", chosen);
        if (chosen)
            if (chosen.pickup_slot) {
                chosen.full_pickup_slot = pickupSlots.filter(s => s.id === chosen.pickup_slot)[0];
                chosen.full_pickup_location = locations.filter(l => l.id === chosen.full_pickup_slot.location_id)[0];

                setAvailablePickUpSlots(pickupSlots.filter(s => {
                    let today = new Date();
                    let day = new Date(s.start_time);
                    return (s.id === chosen.full_pickup_slot.id || (s.location_id === chosen.full_pickup_slot.location_id && s.status == "Available" && day > today))

                }));


            }

        setChosenUser(chosen);
    }

    function loadThisUserNew(event) {
        //console.log("LOAD THIS USER: ")
        //console.log(event.value)
        let chosen = users.filter(u => u.login_name === event.value)[0];
        //console.log("ChosenUser: ", chosen);
        if (chosen)
            if (chosen.pickup_slot) {
                chosen.full_pickup_slot = pickupSlots.filter(s => s.id === chosen.pickup_slot)[0];
                chosen.full_pickup_location = locations.filter(l => l.id === chosen.full_pickup_slot.location_id)[0];

                setAvailablePickUpSlots(pickupSlots.filter(s => {
                    let today = new Date();
                    let day = new Date(s.start_time);
                    return (s.id === chosen.full_pickup_slot.id || (s.location_id === chosen.full_pickup_slot.location_id && s.status == "Available" && day > today))

                }));


            }

        setChosenUser(chosen);
    }

    useEffect(() => {
        loadAvailablePickupSlots(availablePickUpSlots);
        //console.log('Available Pickup Slots: ')
        //console.log(availablePickUpSlots);
    }, [availablePickUpSlots, chosenUser, user]);


    function loadAvailablePickupSlots(optionsArray) {
        let select = document.getElementById('pickup_slot');
        if (select && optionsArray) {
            // Clear existing options
            select.innerHTML = '';

            // Loop through the array and add options
            for (var i = 0; i < optionsArray.length; i++) {
                let option = document.createElement('option');
                let o = optionsArray[i];
                option.text = "Slot #" + o.id + " - " + o.start_time + " at " + locations.filter(l => l.id === o.location_id)[0].name + " (" + o.status + ")";

                option.value = optionsArray[i].id;
                select.add(option);
            }
            select.value = chosenUser.pickup_slot;
            //console.log(chosenUser.pickup_slot)
        }
    }



    function download_users_csv() {
        //** ATTENTION!! No IMAGES ARE DOWNLOADED!! */
        let csv = "";
        let createHeader = true;

        function testField(a) {
            if (a === "password")
                return false;
            if (a === "province")
                return false;
            if (a === "address")
                return false;
            return true
        }

        users.map(user => {

            if (createHeader) {
                for (let item in user) {
                    if (testField(item))
                        csv += item + ",";
                }
                csv += "\n";
                createHeader = false;
            }


            if ((user.type !== "admin" && user.type !== "superAdmin")) {
                for (let item in user) {
                    if (testField(item))
                        if (item === "date_included") {
                            let dateText = new Date(user.date_included).toLocaleDateString();
                            csv += dateText + ",";
                        }
                        else if (item === "email" || item === "log" || item === "phone_number" || item === "first_name" || item === "last_name" || item === "reason" || item === "postal_code") {
                            let freeText = '"' + user[item] + '"';

                            //Removes special characters:
                            freeText = '"' + freeText.replace(/[`~#$%^&*_|+=?'"<>]/gi, '') + '"';
                            //console.log(freeText)
                            csv += freeText + ",";
                        } else if (item === "bike_id") {
                            if (user[item]) {
                                csv += user[item] + ",";

                            } else {
                                csv += "no bike" + ",";
                            }

                        }
                        else if (item === "location") {
                            //console.log(bikes)
                            //console.log(user)
                            //console.log(user)
                            if (user.bike_id) {
                                let loc = locations.filter(l => l.id === bikes.filter(b => b.id === user.bike_id)[0].location)[0].name
                                csv += loc + ",";
                            } else {
                                csv += "no location" + ",";
                            }
                        }
                        else {
                            csv += user[item] + ",";
                        }
                }
                csv += "\n";
            }
            return true
        })


        let hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";

        let today = new Date().toLocaleDateString();
        hiddenElement.download = "bikeMatchWCNdata-" + today + ".csv";
        hiddenElement.click();

    }

    function changePickupSlot() {
        alert("change")
    }


    let [userToUnsubscribe, setUserToUnsubscribe] = useState(0);
    function unsubscribe() {
        let userId = userToUnsubscribe;
        //console.log(users);
        if (window.confirm("Are you sure you want to unsubscribe user " + userId + "? This action cannot be reversed.")) {
            console.log("** EditUser.js - Unsubscribing user: " + userId);

            fetch(`/unsubscribeUser/${userId}`, {
                'method': 'GET',
                'headers': {
                    'Content-Type': 'application/json'
                },

            }).then(response => {
                if (!response.status === 200) {
                    return response.text().then(text => {
                        throw new Error(`** EditUser.js - ${text}`);
                    });
                }
                return response.text();
            }).then(text => {
                alert(text);
                loadUsers();
            }).catch(error => {
                alert(`ERROR: ${error}`);
            });

        }
    }

    function setUserToBeUnsubscribed(event) {
        setUserToUnsubscribe(event.target.value);
    }

    const optionsEmails = loadUsersSelectNew();

    return (
        <>
            <section id="content" className="wrapper">
                <h3>Edit User</h3>
                <div className="contentCard wrapper">
                    <form method="POST">
                   
                    <Select 
                        options={optionsEmails} 
                        onChange={loadThisUserNew} 
                        placeholder="Choose a user by typing email, login name or bike size"
                        value={"choose"}
                        

                    />
                        {/* <br />
                        <label htmlFor="emailBox"> Name (Username - ID) - Email <small>(ordered by email)</small></label>
                        <select onFocus={loadUsersSelect} onChange={loadThisUser} id="emailBox" name="email" value={chosenUser.login_name ? chosenUser.login_name : "choose"}>
                            <option value="choose">-- Choose a user --</option>
                        </select> */}

                       

                        <br />
                        {chosenUser.first_name ? <>
                            <label htmlFor="first_name">First Name</label>
                            <input onChange={handleChangeInput} id="first_name" name="first_name" type="first_name" value={chosenUser.first_name ? chosenUser.first_name : ''} />
                            <br />
                            <label htmlFor="last_name">Last Name</label>
                            <input onChange={handleChangeInput} id="last_name" name="last_name" type="last_name" value={chosenUser.last_name ? chosenUser.last_name : ''} />
                            <br />
                            <label htmlFor="password">Password <small>(you cannot see it, but you can change it)</small></label>
                            <input onChange={handleChangeInput} id="password" name="password" type="password" value={chosenUser.password ? chosenUser.password : ''} />
                            <br />
                            {chosenUser.type !== "superAdmin" ?
                                user.type === "superAdmin" ?
                                    <>
                                        <label htmlFor="location">Location </label>
                                        <select onChange={handleChangeInput} id="location" name="location" type="select" width="100%" required value={chosenUser ? chosenUser.location : ''}>
                                            <option>-- Choose an option --</option>
                                        </select>
                                    </>
                                    : <>
                                        <label htmlFor="location">Location <small>(cannot be changed)</small></label>
                                        <select className="disabledSelect" disabled id="location" name="location" type="select" width="100%" required value={chosenUser ? chosenUser.location : ''}>
                                            <option>-- Choose an option --</option>
                                        </select>
                                    </>
                                : null}
                            {(chosenUser.type !== "admin" && chosenUser.type !== "superAdmin") ?
                                <>
                                    <label htmlFor="age">Age</label>
                                    <input onChange={handleChangeInput} type="number" id="age" name="age" value={chosenUser.age ? chosenUser.age : ''} /><br />
                                    <label htmlFor="gender">Gender</label>
                                    <select onChange={handleChangeInput} id="gender" name="gender" value={chosenUser.gender ? chosenUser.gender : 'choose'}>
                                        <option value="choose" defaultValue>-- Choose --</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                        <option value="Other">Other</option>
                                        <option value="PreferNotToSay">I prefer not to say</option>
                                    </select><br />


                                    <label htmlFor="phone_number">Phone</label>
                                    <input onChange={handleChangeInput} id="phone_number" name="phone_number" type="text" value={chosenUser.phone_number ? chosenUser.phone_number : ''} />

                                    <br />

                                    <label htmlFor="priority"># of Emails Sent (can only be changed by superAdmin)</label>
                                    {user.type === "superAdmin" ?
                                        <input onChange={handleChangeInput} id="priority" name="priority" type="number" min="0" max="10" value={chosenUser.priority ? chosenUser.priority : ''} /> :
                                        <input disabled id="priority" name="priority" type="number" min="0" max="10" value={chosenUser.priority ? chosenUser.priority : ''} />}

                                    <br />
                                    <label htmlFor="date_included">Date included in the system (cannot be changed)</label>
                                    <input readOnly style={{ color: 'gray' }} id="date_included" name="date_included" type="text" value={chosenUser.date_included ? new Date(chosenUser.date_included).toString() : 'not available'} />

                                    <br />

                                    <label htmlFor="postal_code">Postal Code</label>
                                    <input onChange={handleChangeInput} id="postal_code" name="postal_code" type="text" pattern="^[A-Z][0-9][A-Z][\s][0-9][A-Z][0-9]$"
                                        value={chosenUser.postal_code ? chosenUser.postal_code : ''} />
                                    <div id="postalCodeError" ></div>

                                    <label htmlFor="city">City</label>
                                    <input onChange={handleChangeInput} id="city" name="city" type="text" value={chosenUser.city ? chosenUser.city : ''} />

                                    <br />

                                    <div id="chooseHeight" >


                                        <label htmlFor="height_cm">Height:</label><br />
                                        <input onChange={handleChangeInput} onInput={handleChangeInput} id="height_cm" name="height_cm" className="height_cm" type="number" min="100" max="200"
                                            value={chosenUser.height_cm ? chosenUser.height_cm : ''} /> cm<br /><br />

                                        <label htmlFor="bike_size">Bike size:</label>


                                        <select onChange={handleChangeInput} id="bike_size" name="bike_size" value={chosenUser.bike_size ? chosenUser.bike_size : 'choose'}>
                                            <option value="choose">-- Choose --</option>
                                            <option value="Toddler">Toddler</option>
                                            <option value="Kids12">Kids 12''</option>
                                            <option value="Kids14">Kids 14''</option>
                                            <option value="Kids16">Kids 16''</option>
                                            <option value="Kids18">Kids 18''</option>
                                            <option value="Kids20">Kids 20''</option>
                                            <option value="Kids24">Kids 24''</option>
                                            <option value="Kids26">Kids 26''</option>
                                            <option value="XSmall">XSmall</option>
                                            <option value="Small">Small</option>
                                            <option value="Medium">Medium</option>
                                            <option value="Large">Large</option>

                                        </select><br />
                                    </div>


                                    <p id="whyNeed" >
                                        Why do you need a bike?<br />

                                        <textarea onChange={handleChangeInput} id="reason" name="reason" value={chosenUser.reason ? chosenUser.reason : ''}></textarea>
                                    </p>

                                    <label htmlFor="bike_id">Bike (if a bike is reserved or delivered - cannot be changed)</label>
                                    <input style={{ color: 'gray' }} readOnly onChange={handleChangeInput} id="bike_id" name="bike_id" type="text" value={chosenUser.bike_id ? chosenUser.bike_id : 'not selected'} />
                                    <br />


                                    <label htmlFor="pickup_slot">Pickup Slot</label>
                                    <select onChange={handleChangeInput} id="pickup_slot" name="pickup_slot" value={chosenUser.pickup_slot ? chosenUser.pickup_slot : "select"}>

                                        <option value={chosenUser.pickup_slot}>{chosenUser.full_pickup_slot ? "Slot #" + chosenUser.pickup_slot + ' - ' + chosenUser.full_pickup_slot.start_time + " at " + chosenUser.full_pickup_location.name : null} </option>
                                    </select>
                                    <br />
                                </> : null}
                            <p>
                                <label htmlFor="log">LOG (cannot be changed):</label>

                                <textarea readOnly style={{ color: 'gray' }} onChange={handleChangeInput} id="log" name="log" value={chosenUser.log ? chosenUser.log : ''} />
                            </p>



                            {(chosenUser.type !== "admin" && chosenUser.type !== "superAdmin") ?
                                <p >
                                    I would like to receive updates from: <br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_wcn" name="consent_wcn" checked={chosenUser.consent_wcn ? chosenUser.consent_wcn : false} />
                                    <label htmlFor="concent_wcn">The Women's Cycling Network (WCN)</label><br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_local_group" name="consent_local_group" checked={chosenUser.consent_local_group ? chosenUser.consent_local_group : false} />
                                    <label htmlFor="consent_local_group">Cycling Advocacy local group</label><br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_local_bike_hub" name="consent_local_bike_hub" checked={chosenUser.consent_local_bike_hub ? chosenUser.consent_local_bike_hub : false} />
                                    <label htmlFor="consent_local_bike_hub">Local Bike Hub</label><br /><br />

                                    I would like to:<br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_learn_to_ride" name="consent_learn_to_ride" checked={chosenUser.consent_learn_to_ride ? chosenUser.consent_learn_to_ride : false} /><label htmlFor="consent_learn_to_ride">participate in the Learn to Ride Program.</label><br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_bike_skills"
                                        name="consent_bike_skills" checked={chosenUser.consent_bike_skills ? chosenUser.consent_bike_skills : false} /><label htmlFor="consent_bike_skills">improve my bike riding skills</label><br />
                                    <input onChange={handleChangeInput} type="checkbox" id="consent_basic_mechanics" name="consent_basic_mechanics" checked={chosenUser.consent_basic_mechanics ? chosenUser.consent_basic_mechanics : false} /><label htmlFor="consent_basic_mechanics">learn basic bike mechanics</label><br /><br />


                                </p> : null}
                        </> : null}

                        <p>
                            <label id="errorBox" name="erroBox"></label>
                        </p>
                        {chosenUser.first_name ?

                            <div className="wrapper centering displayFlex">

                                {chosenUser.type !== "superAdmin" ?
                                    <>
                                        <button id="deleteUserButton" type="button" onClick={deleteUser} className="otherButton redButton">
                                            Delete User
                                        </button>

                                        <button id="addUserButton" type="button" onClick={editUser} className="otherButton">
                                            Save User
                                        </button></> : null}
                            </div>
                            : null}
                        <div className="centering">
                            <button id="createBackupButton" type="button" onClick={createBackup} className="otherButton">
                                Create a backup of the database
                            </button>

                            {user.type === "superAdmin" ?
                                <button id="createCSVButton" type="button" onClick={download_users_csv} className="otherButton">
                                    Download Users DB (CSV)
                                </button> : null
                            }

                            {/* {user.type === "superAdmin" ?
                                <div className="unsubscribe">
                                    <label htmlFor="userToUnsubscribe"><b>Unsubscribe user:</b> (This action cannot be reversed)</label>
                                    <input type="number" id="userToUnsubscribe" value={userToUnsubscribe} onChange={setUserToBeUnsubscribed} style={{ width: '10%' }}></input>
                                    <button type="button" onClick={unsubscribe} className="otherButton" style={{ width: '20%', fontSize: '16px', padding: '5px', backgroundColor: 'red' }}>Unsubscribe User</button>
                                </div> : null} */}



                        </div>

                    </form>
                </div>


            </section>



        </>
    )
}
export default EditUser;