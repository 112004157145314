import './Bike.css'
import { useEffect, useState } from 'react';
const Bike = ({ bike, bikes, setBikes, user, setUser, selectedBike, setSelectedBike, sendEmail, logout }) => {
    //console.log(pickupSlots);
    //console.log('BIKE: ', bike)
    let [pickupDate, setPickupDate] = useState();

    let [pickupSlots, setPickupSlots] = useState([]);

    let [localBike, setLocalBike] = useState(bike);

    const magnifyingGlass = require('./images/mag.png');

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    useEffect(() => {
        fetch('/getBikeWithPic', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(bike)
        }).then(res => {
            let response = res;

            return (response.json());
        }).then(res => {
            let response = res
            //console.log('INSIDE RESPONSE')
            //console.log(response[0])
            let local = { ...localBike }
            local.picture = response[0].picture
            setLocalBike(local)
        })
    }, []);


    useEffect(() => {
        loadPickupSlots();
    }, []);


    function loadPickupSlots() {
        let data = {};
        fetch("/getAllDates", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                let today = new Date();

                if (final) {
                    setPickupSlots(final.filter(spot => {
                        let year = parseInt(spot.start_time.split('/')[0])
                        let month = parseInt(spot.start_time.split('/')[1])
                        let day = parseInt(spot.start_time.split('/')[2])
                        let fullDay = new Date(year + "/" + month + "/" + day)

                        let dayMs = fullDay.getTime();
                        let todayMs = today.getTime();

                        //Pickup spots that are less than 12h away will not be shown to users
                        let halfDayMs = (12 * 60 * 60 * 1000);

                        return (dayMs >= (todayMs + halfDayMs) && spot.status != "Deleted" && spot.status === "Available")
                    }));

                } else {
                    setPickupSlots([]);
                }


            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the pickup slots. Could you logout, login and try again?")
                    logout()
                }
            )


    }


    let [locations, setLocations] = useState([]);
    useEffect(() => {
        loadLocations();
    }, []);
    function loadLocations() {

        fetch("/getAllLocations")
            .then(res => {
                let response = res;
                //console.log(response);
                return (response.json());
            })
            .then(response => {
                let final = response;
                //console.log('Loading locations: ',final);
                setLocations(final);

            })

            .catch(
                e => {
                    //console.log("Error:" + e)
                    alert("There was an error connecting to our server to load the locations. Could you logout, login and try again?")
                    logout()
                }

            )
    }

    let [fullLocationDetails, setFullLocationDetails] = useState([])
    useEffect(() => {
        //console.log(bike)
        let thisLocation = locations.filter(l => l.id === bike.location)[0]
        //console.log('Set details: ',thisLocation)
        setFullLocationDetails(thisLocation)
    }, [locations, bike.location]);

    function formatPickupDateAndLocation(slot_id) {
        if (pickupSlots) {
            slot_id = parseInt(slot_id);
            //console.log('Slot id: ', slot_id);
            let thisSlotLocationId = pickupSlots.filter(s => s.id === slot_id)[0].location_id;
            //console.log('This Slot Location Id: ',thisSlotLocationId)
            //console.log('Locations: ', locations);
            let thisLocationDetails = locations.filter(loc => loc.id === thisSlotLocationId)[0];
            //console.log('This Location Details: ',thisLocationDetails)
            thisLocationDetails.start_time = pickupSlots.filter(s => s.id === slot_id)[0].start_time;
            //console.log('This Location Details: ',thisLocationDetails)
            return thisLocationDetails;

        }
        return {}
    }

    function handleChangeDateTime(event) {
        //console.log(event.target.value)
        //console.log('bike id'+bike.id)
        //console.log('selected bike'+selectedBike)
        setPickupDate(event.target.value);
    }
    useEffect(() => {
        setSelectedBike(bike.id);
    }, [pickupDate, bike.id]);



    function handleReserve() {
        let reservation = { ...user };
        if (pickupDate) {
            let formattedLocation = formatPickupDateAndLocation(pickupDate);
            let confirmReserve = window.confirm("Are you sure you want to reserve the bike (" + bike.tag + ") on " + formattedLocation.start_time + " at " + formattedLocation.name + "?");
            if (confirmReserve) {
                reservation.pickup_slot = parseInt(pickupDate);
                reservation.bike_id = bike.id;

                //console.log('Reserve Bike! ' + reservation.bike_id + " - " + reservation.pickup_slot);

                fetch('/updateUserReserveBike', {
                    'method': 'POST',
                    'headers': {
                        'Content-Type': 'application/json'
                    },
                    'body': JSON.stringify(reservation)
                }).then(response => {
                    if (response.status === 200) {
                        //alert('User updated!');
                        localStorage.setItem('user', JSON.stringify(reservation));
                        let recipient = user.email;
                        let subject = "Confirmation of your bike reservation";
                        let text = 'Hi ' + user.first_name + ' ' + user.last_name + '.' + "We hope you're excited - soon you'll have a bike to ride and be able to get around the city on two wheels! The bike you reserved for "+user.user_name+" is " + bike.tag + ". We'll have  your bike ready for you at the BikeMatchWCN Bike Pick-Up Event at " + formattedLocation.name + ": " + formattedLocation.address + ", " + formattedLocation.postal_code + ". Our phone number is " + formattedLocation.phone_number + ". Your appointment for pick up is " + formattedLocation.date + ". Please be on time and bring this confirmation email with you. If you are unable to attend, reply to this email or call us at " + formattedLocation.phone_number + " to let us know  in advance and we will try to make other arrangements. Unless other arrangements are made, all bikes that are not pick-up will be returned to BikeMatchWCN to be given out.";

                        let html = 'Hi ' + user.first_name + ' ' + user.last_name + '.' + "<p>We hope you're excited - soon you'll have a bike to ride and be able to get around the city on two wheels!</p><p>The bike reserved for " + user.login_name + " is <b>" + bike.tag + "</b>.</p><p>We'll have your bike ready for you at the BikeMatchWCN Bike Pick-Up Event at <b>" + formattedLocation.name + ": " + formattedLocation.address + ", " + formattedLocation.postal_code + "</b>. Our phone number is " + formattedLocation.phone_number + ". Your appointment for pick up is <b>" + formattedLocation.start_time + "</b>.</p><p>Please be on time and bring this confirmation email with you.</p><p>If you are unable to attend, reply to this email or call us at " + formattedLocation.phone_number + " to let us know  in advance and we will try to make other arrangements. Unless other arrangements are made, <b>all bikes that are not pick-up will be returned to BikeMatchWCN to be given out</b>.</p>";

                        let replyTo = formattedLocation.email
                        sendEmail(recipient, user.id, subject, text, html, replyTo);

                        let textToHub = 'User ' + user.first_name + ' ' + user.last_name + " (" + recipient + ") reserved the bike " + bike.tag + " at " + formattedLocation.name + ": " + formattedLocation.address + " - " + formattedLocation.start_time + ". Reply to this email if you need to talk to the user.";
                        sendEmail(replyTo, user.id, "BIKE RESERVATION", textToHub, textToHub, recipient);
                        
                        sendEmail("ana@garagelab.ca", user.id, "NEW BIKE RESERVATION", textToHub, textToHub, replyTo);

                        //console.log('Reservation: ', reservation)
                        setUser(reservation);
                        setSelectedBike(undefined)

                    } else {
                        // read the response body as plain text
                        response.text().then(text => {
                            //console.log('Updated user: ' + text);
                            alert(text);
                        });
                    }
                }).catch(error => {
                    //console.log(error);
                    alert('There was an error reserving your bike. Please try again.')
                });
                return false;
            }
        } else {
            alert("You must pick up a date and time!");
        }
    }

    function deleteThisBike() {
        //let bikeId = bike.id;
        let bikeTag = bike.tag;
        let today = new Date();
        let text = 'Are you sure you want to delete bike (' + bikeTag + ')?';

        let deleteConfirm = window.confirm(text);
        if (deleteConfirm) {
            let old = bike.log ? bike.log : "";
            bike.log = old + "\nBike deleted " + today + " by " + user.email + ".";
            fetch('/deleteThisBike', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(bike)
            }).then(response => {

                let bs = [...bikes]
                //console.log(bs);
                bs.filter(b => b.id === bike.id)[0].status = "Deleted";
                setBikes(bs);
                alert('Bike deleted!');

            }).catch(error => {
                //console.log(error);
                alert("There was an error deleting this bike. Please check if the bike was deleted, and try again. Error: " + error)
            });
        }
    }

    function restoreThisBike() {
        //let bikeId = bike.id;
        let bikeTag = bike.tag;
        let today = new Date();
        let text = 'Are you sure you want to restore bike (' + bikeTag + ')?';
        let restoreConfirm = window.confirm(text);
        if (restoreConfirm) {
            let old = bike.log ? bike.log : "";
            bike.log = old + "\nBike restored " + today + " by " + user.email + ".";
            fetch('/restoreThisBike', {
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(bike)
            }).then(response => {

                let bs = [...bikes]
                //console.log(bs);
                bs.filter(b => b.id === bike.id)[0].status = "Available";
                setBikes(bs);
                alert('Bike restored!');

            }).catch(error => {
                //console.log(error);
                alert("There was an error restoring this bike. Please check if the bike was restored and try again. Error:" + error)
            });
        }
    }




    //TRYING TO FIX BUGGY SELECT:
    useEffect(() => {
        //console.log('load times...');
        if (user.type !== "admin" && user.type !== "superAdmin")
            loadDateTimeSelectLocal();
    }, [pickupSlots, user.type]);

    function loadDateTimeSelectLocal() {
        let allOp = document.getElementById("date" + bike.id);
        //console.log(pickupSlots)

        let thisLocationSlots = pickupSlots.filter(slot => slot.location_id === bike.location);
        if (thisLocationSlots.length === 0 && pickupSlots.length != 0) {
            let opt = document.createElement('option');
            opt.value = 'choose';
            opt.innerHTML = 'All current pickup dates are less than 12 hours away. Please contact the bike hub.';
            allOp.innerHTML = '';
            allOp.appendChild(opt);
        }
        for (let i = 0; i < thisLocationSlots.length; i++) {
            if (thisLocationSlots[i].status === "Available") {
                let opt = document.createElement('option');
                opt.value = thisLocationSlots[i].id;
                opt.innerHTML = thisLocationSlots[i].start_time;
                allOp.appendChild(opt);
                //console.log(allOp)
            }
        }
    }




    return (
        <div className="bikeBox">
            <div className="popup-container">


                {showPopup && (
                    <div className="popup" onClick={togglePopup}>
                        <p className="clickToClose">CLICK TO CLOSE</p>
                        <img src={`data:image/jpeg;base64,${localBike.picture}`} alt={bike.tag} />
                    </div>
                )}
            </div>

            {localBike.picture ?
                <div id="bikeImages">

                    <div className="bikeImage">
                        <img onClick={togglePopup} className="bikeImageFile" src={`data:image/jpeg;base64,${localBike.picture}`} alt={bike.tag} />
                        <img className="magGlass" onClick={togglePopup} src={magnifyingGlass} alt={"bigger image"} />


                    </div>


                </div> : null}
            <div className="bikeTag"> {localBike.tag}
            </div>
            <div className="bikeDescription">

                <p>{bike.size} {bike.type} bike, {bike.frame} frame{bike.wheel_size ? ", " + bike.wheel_size + "'' wheel" : null}.</p>
                <p>{bike.description}</p>
                <p><b>Date Included:</b> {bike.date_included}</p>
                {/* <p> Donated by: {bike.donor_name}</p> */}
            </div>
            {fullLocationDetails ? <>
                <div className="bikeLocation"> <b>Location:</b> {fullLocationDetails.name}</div>
                <div className="bikeLocation"> <b>Web:</b> <a href={'https://' + fullLocationDetails.website} target="_blank" rel="noreferrer">{fullLocationDetails.website} </a>
                    {fullLocationDetails.alternative_website ?
                        <> or <a href={'http://' + fullLocationDetails.alternative_website} target="new"> {fullLocationDetails.alternative_website}</a>
                        </>
                        : null}
                </div>
                <div className="bikeLocation"> <b>Address:</b> {fullLocationDetails.address}, {fullLocationDetails.postal_code}, {fullLocationDetails.city}, {fullLocationDetails.province}</div>
                <div className="bikeLocation"> <b>Email:</b> <a href={`mailto:` + fullLocationDetails.email}>{fullLocationDetails.email}</a></div>
                <div className="bikeLocation"> <b>Contact Info:</b> {fullLocationDetails.phone_number} {fullLocationDetails.contact ? ", " + fullLocationDetails.contact : null}</div>
            </> : <p>Loading...</p>}

            <b>Bike status:</b> {bike.status === "Donated" ? "Delivered" : bike.status} {bike.email ? " - " + bike.email : null}

            {pickupSlots.length > 0 ?
                user.type === "recipient" ?
                    <>
                        <div className="chooseDateTime">
                            <label htmlFor="date"><b>Choose a pickup date:</b><br /> </label>
                            {/* <select onClick={loadDateTimeSelect} onChange={handleChangeDateTime} className="date" id={"date"+bike.id} name="date"> */}
                            <select onChange={handleChangeDateTime} className="date" id={"date" + bike.id} name="date">
                                <option value="choose">-- Choose --</option>
                            </select><br />
                        </div>
                        <div className="reserveButton">
                            {
                                selectedBike === bike.id && pickupDate && pickupDate !== "choose" ?
                                    <button type="button" onClick={handleReserve} >Reserve</button>
                                    : null
                            }
                        </div>
                    </>
                    : null
                : <div className="errorMessage">No pickup dates are available. Please contact the bike hub.</div>
            }

            {(user.type === "admin" || user.type === "superAdmin") ?
                <>
                    {bike.status === "Available"
                        ? <button type="button" onClick={deleteThisBike} className="finalCancelButton">DELETE THIS BIKE</button> : null}
                    {bike.status === "Deleted" || bike.status === "Needs Repair" || bike.status === "For Parts Only" || bike.status === "Waiting for Approval"
                        ? <button type="button" onClick={restoreThisBike} className="finalConfirmButton">MAKE THIS BIKE AVAILABLE</button> : null}
                    <textarea className="bikeLog" rows="6" readOnly>{"LOG: " + bike.log}</textarea>
                </>
                : null
            }
        </div>

    );
}
export default Bike;